import React from 'react'
import Login from '../components/Login'
import PrivateRoute from '../components/PrivateRoute'
import Layout from '../components/Layout'
import { navigate } from '@reach/router'
import { isLoggedIn } from '../utils/auth'
import { isBrowser } from '../utils/misc'

class LoginPage extends React.Component {
  componentDidMount() {}

  render() {
    if (isBrowser) {
      isLoggedIn().then((user) => {
        if (user) {
          navigate('/')
        }
      })
      return (
        <Layout>
          <PrivateRoute path="/login" component={Login} fsOnly="false" />
        </Layout>
      )
    } else {
      return null
    }
  }
}

export default LoginPage
